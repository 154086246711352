import { t } from '@lingui/macro'
import dayjs from 'dayjs'
import { useMediaQuery, useTheme } from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { useQueryState } from 'next-usequerystate'
import SuspenseBoundary from '@src/SuspenseBoundary'
import OrdersContent from '@src/Orders/OrdersContent'
import { colors, constants, spacing } from '@src/Styles'
import { useState } from 'react'
import SearchInput from '@src/SearchInput'
import { useTypographyContext } from '@src/Typography'
import { CMAOrderType } from '@src/types'
import OrdersSearchButton from '@src/Orders/OrdersSearchButton'
import OrdersPaymentType from '@src/Orders/OrdersPaymentType'
import { CsbOrderMetadata } from '@kjt01/greendot-wasm'
import { serializeCurrency } from '@src/Currency/helpers'
import OrdersServiceType from '@src/Orders/OrdersServiceType'
import DateTimeInvalid from '@src/DateTime/DateTimeInvalid'
import { useDateQueryState } from '@src/QueryState'

const EMPTY_SUMMARY: CsbOrderMetadata = {
  cash_amount: serializeCurrency(0),
  credit_card_amount: serializeCurrency(0),
  tip_amount: serializeCurrency(0),
  total_sales: serializeCurrency(0),
  compensation_total: serializeCurrency(0),
  compensation_count: 0,
  delivery_count: 0,
  pickup_count: 0,
  total_count: 0,
}

const Orders = () => {
  const { typography } = useTypographyContext()
  const theme = useTheme()
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))

  const { date, setDate } = useDateQueryState()

  const [serviceType, setServiceType] = useQueryState<CMAOrderType | 'all'>(
    'serviceType',
    {
      parse: (query) => query as CMAOrderType | 'all',
      shallow: false,
      defaultValue: 'all',
    },
  )

  const [isReady, setIsReady] = useState(false)
  const [search, setSearch] = useState('')
  const [summary, setSummary] = useState<CsbOrderMetadata>(EMPTY_SUMMARY)

  const [paymentType, setPaymentType] = useQueryState('paymentType', {
    parse: (query: string) => query as 'cash' | 'credit' | 'compensated',
    shallow: false,
  })

  const startDate = dayjs(date)
    .add(4, 'hours')
    .format('YYYY-MM-DD HH:mm:ss[.000000]')

  const endDate = dayjs(date)
    .add(1, 'day')
    .add(3, 'hours')
    .add(59, 'minutes')
    .add(59, 'seconds')
    .format('YYYY-MM-DD HH:mm:ss[.000000]')

  const isDateValid =
    startDate !== 'Invalid Date' &&
    startDate > '1969' && // the farthest back get_location_orders will return results
    startDate < '2100' && // default maxDate of DatePicker
    endDate !== 'Invalid Date'

  return (
    <>
      <div
        css={{
          display: 'flex',
          gap: spacing.normal,
          paddingBottom: spacing.normal,
          flexDirection: 'column',
        }}
      >
        <div css={{ display: 'flex', gap: spacing.base }}>
          <div css={{ flex: 1, minWidth: 150, height: '100%' }}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              localeText={{
                datePickerToolbarTitle: t`Select Date`,
                cancelButtonLabel: t`Cancel`,
                okButtonLabel: t`Ok`,
              }}
            >
              <DesktopDatePicker
                disabled={!isReady && isDateValid}
                sx={{
                  width: '100%',
                  height: '100%',
                  background: colors.white[100],
                  borderRadius: `${constants.borderRadius.medium}px`,
                  '.MuiInputBase-adornedEnd': {
                    height: '100%',
                  },
                  fieldset: {
                    border: `1px solid ${colors.neutrals[50]}`,
                    borderRadius: `${constants.borderRadius.medium}px`,
                  },
                  input: { ...typography.title.mediumRegular },
                }}
                value={dayjs(date)}
                onChange={(value) => {
                  setSummary(EMPTY_SUMMARY)
                  const selectedDate = dayjs(value).format('YYYY-MM-DD')
                  setDate(selectedDate)
                }}
              />
            </LocalizationProvider>
          </div>
          {isMobileView ? null : <div css={{ flex: 1 }} />}
          {isMobileView ? (
            <OrdersSearchButton />
          ) : (
            <div css={{ flex: 1 }}>
              <SearchInput
                placeholder={t`Search order`}
                search={search}
                setSearch={setSearch}
              />
            </div>
          )}
        </div>
        <OrdersServiceType
          summary={summary}
          serviceType={serviceType}
          setServiceType={setServiceType}
        />
        <OrdersPaymentType
          summary={summary}
          paymentType={paymentType}
          setPaymentType={setPaymentType}
        />
      </div>

      <div css={{ flex: 1 }}>
        {!isDateValid ? (
          <DateTimeInvalid />
        ) : (
          <SuspenseBoundary>
            <OrdersContent
              search={search}
              startDate={dayjs(date)
                .add(4, 'hours')
                .format('YYYY-MM-DD HH:mm:ss[.000000]')}
              endDate={dayjs(date)
                .add(1, 'day')
                .add(3, 'hours')
                .add(59, 'minutes')
                .add(59, 'seconds')
                .format('YYYY-MM-DD HH:mm:ss[.000000]')}
              serviceType={serviceType}
              setIsReady={setIsReady}
              setSummary={setSummary}
              paymentType={paymentType}
            />
          </SuspenseBoundary>
        )}
      </div>
    </>
  )
}

export default Orders
