import { CsbOrderMetadata } from '@kjt01/greendot-wasm'
import { msg, t } from '@lingui/macro'
import { MessageDescriptor } from '@lingui/core'
import { useLingui } from '@lingui/react'
import { MenuItem, Select, useMediaQuery, useTheme } from '@mui/material'
import { getUsd, parseCurrency } from '@src/Currency/helpers'
import { colors, constants, spacing } from '@src/Styles'
import { useTypographyContext } from '@src/Typography'

type Option = {
  label: MessageDescriptor
  value: 'cash' | 'credit' | 'compensated' | null
}

const options: Option[] = [
  { label: msg`Cash`, value: 'cash' },
  { label: msg`Credit`, value: 'credit' },
  { label: msg`Total (Cash + Credit)`, value: null },
  { label: msg`Compensated`, value: 'compensated' },
]

type PaymentType = 'cash' | 'credit' | 'compensated' | null

const OrdersPaymentType = ({
  summary,
  paymentType,
  setPaymentType,
}: {
  summary: CsbOrderMetadata
  paymentType: PaymentType
  setPaymentType: React.Dispatch<React.SetStateAction<PaymentType>>
}) => {
  const theme = useTheme()
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))

  const { i18n } = useLingui()
  const { typography } = useTypographyContext()

  const mobileLabel = options.find((o) => o.value === paymentType)?.label

  return isMobileView ? (
    <div css={{ width: '100%' }}>
      <Select
        displayEmpty
        value={paymentType ?? ''}
        onChange={(e) => {
          const value = e.target.value as PaymentType | ''
          setPaymentType(value === '' ? null : value)
        }}
        renderValue={(value) => {
          if (paymentType == null) {
            return t`Total (Cash + Credit)`
          }

          const label = options.find((o) => o.value === value)?.label
          return label != null && i18n._(label)
        }}
        sx={{
          width: '100%',
          fieldset: {
            border: `${constants.borderWidths.regular}px solid ${colors.neutrals[50]}`,
          },
          borderRadius: `${constants.borderRadius.medium}px`,
          outline: 'none',
          caretColor: colors.neutrals[400],
          color: colors.neutrals[500],
          ...typography.title.mediumRegular,
          background: colors.white[100],
          ':disabled': {
            background: colors.neutrals[50],
            opacity: 0.5,
          },
          ':focus': {
            borderColor: colors.neutrals[400],
          },
        }}
      >
        {options.map((option) => {
          if (
            option.value === 'compensated' &&
            summary.compensation_count === 0
          )
            return null

          return (
            <MenuItem key={option.value ?? 'total'} value={option.value ?? ''}>
              {i18n._(option.label)}
            </MenuItem>
          )
        })}
      </Select>
      <div
        css={{
          paddingTop: spacing.normal,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          css={{
            ...typography.label.medium,
            fontWeight: 700,
            textTransform: 'uppercase',
          }}
        >
          {mobileLabel && i18n._(mobileLabel)}
        </div>
        <div
          css={{
            ...typography.title.mediumRegular,
            display: 'flex',
            alignItems: 'center',
            gap: spacing.small,
          }}
        >
          {paymentType === 'cash' && getUsd(summary.cash_amount)}
          {paymentType === 'credit' && (
            <>
              {getUsd(
                parseCurrency(summary.credit_card_amount) -
                  parseCurrency(summary.tip_amount),
              )}
              <div css={{ ...typography.label.medium }}>
                + {getUsd(summary.tip_amount)}
              </div>
            </>
          )}
          {paymentType == null && (
            <>
              {getUsd(summary.total_sales)}
              <div css={{ ...typography.label.medium }}>
                + {getUsd(summary.tip_amount)}
              </div>
            </>
          )}
          {paymentType === 'compensated' && (
            <>{getUsd(summary.compensation_total)}**</>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div
      css={{
        display: 'flex',
        justifyContent: 'center',
        padding: spacing.base,
        gap: spacing.normal,
        background: colors.primary2[50],
        color: colors.neutrals[500],
        border: `1px solid ${colors.primary2[100]}`,
        borderRadius: constants.borderRadius.medium,
      }}
    >
      {options.map((option) => {
        if (option.value === 'compensated' && summary.compensation_count === 0)
          return null

        return (
          <div
            key={option.value ?? 'total'}
            css={{ display: 'flex', width: '100%' }}
          >
            {option.value === 'compensated' && (
              <div
                css={{
                  borderLeft: `1px solid ${colors.primary2[100]}`,
                  paddingInline: spacing.base,
                }}
              />
            )}
            <button
              css={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingBlock: spacing.small,
                background:
                  paymentType === option.value ? colors.white[500] : 'none',
                border: 'none',
                borderRadius: constants.borderRadius.medium,
                cursor: 'pointer',
                ':hover': {
                  background: colors.primary2[25],
                },
                ':active': {
                  color: colors.white[500],
                  background: colors.primary2[500],
                },
              }}
              onClick={() => setPaymentType(option.value)}
            >
              <div
                css={{
                  ...typography.label.large,
                  textTransform: 'uppercase',
                }}
              >
                {i18n._(option.label)}
              </div>
              <div css={{ ...typography.title.mediumRegular }}>
                {option.value === 'cash' && getUsd(summary.cash_amount)}
                {option.value === 'credit' && (
                  <>
                    {getUsd(
                      parseCurrency(summary.credit_card_amount) -
                        parseCurrency(summary.tip_amount),
                    )}
                    <div css={{ ...typography.label.small }}>
                      (+ {getUsd(summary.tip_amount)})
                    </div>
                  </>
                )}
                {option.value == null && (
                  <>
                    {getUsd(summary.total_sales)}
                    <div css={{ ...typography.label.small }}>
                      (+ {getUsd(summary.tip_amount)})
                    </div>
                  </>
                )}
                {option.value === 'compensated' && (
                  <>{getUsd(summary.compensation_total)}**</>
                )}
              </div>
            </button>
          </div>
        )
      })}
    </div>
  )
}

export default OrdersPaymentType
